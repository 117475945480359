(function($) {

// Get the last purchased sku data for signed out but recognized user
function _getLastPurchaseFromCookie() {
  // turn on automatic storage of JSON objects passed as the cookie value
  $.cookie.json = true;
  var rsp = JSON.parse($.cookie("persistent_user_last_purchase"));

  function _hidePurchase() {
    // Display the video module from the anon version instead:
    $('.my-feed-tray__col.last-purchased').after($('.my-feed-tray__video-wrapper')).remove();
  }

  // Avoid errors:
  var exit = !rsp;
  exit = exit || typeof rsp.skus == 'undefined';
  exit = exit || typeof rsp.products == 'undefined';
  exit = exit || typeof generic.cookie == 'undefined';
  exit = exit || !rsp.products.length;
  exit = exit || typeof rsp.products[0].PROD_RGN_NAME == 'undefined';
  if (exit) {
    _hidePurchase();
    return;
  }

  var sku = rsp.skus[0];
  var prod = rsp.products[0];
  _.defaults(sku, prod);
  site.lastPurchase = sku;
  $(document).trigger('lastPurchaseLoaded');

}

// Get the last purchased sku data:
function _getLastPurchase() {
  // Authenticated users only:
  var signedIn = site.userInfoCookie.getValue('signed_in') - 0;
  if (!signedIn) return;

  function _hidePurchase() {
    // Display the video module from the anon version instead:
    $('.my-feed-tray__col.last-purchased').after($('.my-feed-tray__video-wrapper')).remove();
  }

  generic.jsonrpc.fetch({
    method : 'user.pastPurchased',
    params: [{
      product_fields: ['url', 'PROD_RGN_NAME', 'PRODUCT_ID'],
      sku_fields: ['SKU_BASE_ID', 'SMALL_IMAGE', 'formattedPrice', 'PRODUCT_SIZE', 'HEX_VALUE_STRING', 'SHADENAME'],
      get_product: 1,
      max_length: 1
    }],
    onSuccess: function(jsonRpcResponse) {
      var rsp = jsonRpcResponse.getValue();

      // Avoid errors:
      var exit = !rsp;
      exit = exit || typeof rsp.skus == 'undefined';
      exit = exit || typeof rsp.products == 'undefined';
      exit = exit || typeof generic.cookie == 'undefined';
      exit = exit || !rsp.products.length;
      exit = exit || typeof rsp.products[0].PROD_RGN_NAME == 'undefined';
      if (exit) {
        _hidePurchase();
        return;
      }

      // turn on automatic storage of JSON objects passed as the cookie value
      $.cookie.json = true;
      $.cookie('persistent_user_last_purchase', JSON.stringify(rsp), { expires: 365, path: '/' });

      var sku = rsp.skus[0];
      var prod = rsp.products[0];
      _.defaults(sku, prod);
      site.lastPurchase = sku;
      $(document).trigger('lastPurchaseLoaded');
    },
    onFailure: function(jsonRpcResponse) {
      _hidePurchase();
    }
  });
}


$(document).on('lastPurchaseLoaded', function(){
  if ( $('.my-feed-tray__auth .product-thumb-wrapper').is(':empty') ) {
    site.lastPurchase = site.lastPurchase || {};
    var template = $('script.inline-template[path="product_thumb"]').html();
    var rendered = Mustache.render( template, site.lastPurchase );
    var $thumb = $('.my-feed-tray__auth .product-thumb-wrapper').append( rendered );

    site.createAddButton($('.js-add-to-cart', $thumb));

    if (typeof site.lastPurchase.HEX_VALUE_STRING != 'undefined' && site.lastPurchase.HEX_VALUE_STRING) {
      var hexes = site.lastPurchase.HEX_VALUE_STRING.split(',');
      if (hexes.length <= 1) return;
      var containerClass = 'swatches--single';
      if (hexes.length == 2) {
        containerClass = 'swatches--duo';
      } else if (hexes.length == 3) {
        containerClass = 'swatches--trio';
      } else if (hexes.length == 4){
        containerClass = 'swatches--quad';
      } else if (hexes.length == 5) {
        containerClass = 'swatches--quint';
      }
      $('.swatch__container', $thumb).addClass(containerClass);
      for (var i = 0; i < hexes.length; i++) {
        $('.swatch__container', $thumb).append('<div class="swatch swatch--' + (i + 1) + '" style="background-color: ' + hexes[i] + ';"></div>');
      }
    }
  }
});

Drupal.behaviors.ELB_MyFeed = {
  attach: function(context, settings) {
    var $authTray = $('.my-feed-tray__auth', context);
    if (!$authTray.length) return;
    var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
    var signedIn = site.userInfoCookie.getValue('signed_in') - 0;
    var $trigger = $( '.my-feed-drawer .drawer-formatter__trigger', context );
    $trigger.on('click', function() {
      var myWindow = window.open("/account/beauty_feed.tmpl", "_self");
    });

    // Sign out link returns you to current page and expands special offers (see
    // special_offers.js for cookie handling)

    // Drawers need to be initialized before we can bind events to them:
    site.drawers.init(context);
    var $signOutLink = $('.my-feed-summary__signout', context);
    var returnURL = window.location.pathname;
    var signOutURL = $signOutLink.attr('href') + '&success_url=' + returnURL;
    $signOutLink.attr('href', signOutURL).on('click', function(event) {
      $.cookie('expandOfferTray', 1, {path: '/'});
    });
    $signOutLink.on('click', function(event) {
      event.preventDefault();
    });

    _getLastPurchase();

    //fix repeating touts issue
    $('.formatter-beauty-feed__isotope-item .slick-dots').each(function() {
      $(this).nextAll().css('display','none');
    });

    // Ensure this script doesn't break site if perlgem isn't running:
    if (typeof site != 'undefined' && typeof site.userInfoCookie != 'undefined') {
      var firstName = site.userInfoCookie.getValue('first_name') || persistentCookie.first_name;
      var firstTime = !!persistentCookie.first_time;
      if (!firstName) return;
      if (!firstTime) {
        if (!signedIn) _getLastPurchaseFromCookie()
        $('.my-feed-tray__anon', context).hide();
        $('.my-feed-tray__auth', context).show();
      }
    
      var $template = $('.my-feed-summary--auth');
      var rendered = Mustache.render( $template.html(), { first_name: firstName } );
      $template.html( rendered );
    }
  }
};

})(jQuery);
